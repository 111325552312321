import React from 'react';
import { Link } from 'gatsby';

// import Like from "./icons/Like";
import LikeIcon from "../assets/images/icons/like.svg";
import LikeIconActive from "../assets/images/icons/like-active.svg";

import { getDiscount } from '../utils/tools';

import '../assets/css/card.css';

/**
 * @name Card Component
 * present some data inside a card
 * @param {Object} data contain some data to render in card
 */
const Card = ({ data, enableAction, actionEvent, isLiked, removeEvent, ...rest} ) =>  {
  let { id, name, slug, images, price, regular_price, average_rating, rating_count, acf } = data;
  let discount = getDiscount(regular_price, price);
  /**
   * this called when user click a like button
   * inside card action section
   * @name onClick
   * @param {DOMEvent}
   */
  let onClick;
  if( !removeEvent ) {
    onClick = e => {
      e.preventDefault();
      actionEvent[0]({
        slug, image: images[0], price, regular_price, name, acf, average_rating, rating_count,
      });
    };
  }
  average_rating = parseFloat(average_rating) ? (average_rating*100/5)+10 : 0;

  return (
    <div className="card col s6 m3" data-id={`${id}`} id={slug} {...rest}>
      <Link to={`/produit/${slug}/`} className='card__container'>
        <div
          className="card__image loading-background js-lazy-background"
          data-background={images[0].src}
        />
        <h2 className="card__title capitalize">{ name }</h2>
        <p className="card__description mb0">
          { price }DA&nbsp;
          {
            discount !== 0 && <span className="card__description_grey line-through">{regular_price}DA</span>
          }
        </p>
        { discount !== 0 && <strong className="card__mark center">{discount}%</strong>}
        {
          enableAction && (
            <div className="card__action">
              <div className="card__rating left">
                <i className="rating__star">★★★★★</i>
                <i className="rating__star-fake" style={{width: `${ average_rating}%` }}>★★★★★</i>
              </div>
              <p className="card__action__count mb0 left"> &nbsp;({rating_count})</p>
              <button
                className={`card__action__button right p0 ${isLiked? 'card__action__button_active' : ''}`}
                onClick={ onClick } type="button">
                <img src={ isLiked ? LikeIconActive : LikeIcon } alt='like heart' />
              </button>
            </div>
          )
        }
        {
          removeEvent && (
            <div className="card__action">
              <div className="card__rating left">
                <i className="rating__star">★★★★★</i>
                <i className="rating__star-fake" style={{width: `${ average_rating}%` }}>★★★★★</i>
              </div>
              <p className="card__action__count mb0 left"> &nbsp;({rating_count})</p>
              <button
                className={`card__action__button right p0 card__action__button_active`}
                onClick={ (e) => { e.preventDefault(); removeEvent(slug)} } type="button">
                <img src={ isLiked ? LikeIconActive : LikeIcon } alt='like heart'  />
              </button>
            </div>
          )
        }
      </Link>
    </div>
  );

}

export default Card;
