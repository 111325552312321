import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Card from "../components/Card";

import { removeFromFavoriteList, getLikedProducts } from "../utils/actions/product";
import { formatData } from "../utils/tools";

import EmptyBasketIcon from '../assets/images/icons/shopping-basket.svg'

class FavoriteProducts extends React.Component {

  state = {
    likedProducts: []
  }

  /**
   * @name refreshState
   * refresh a current state by fetching data from localStorage
   *
   */
   refreshState = () => {
     this.setState({
       likedProducts: formatData( getLikedProducts(), 4 ),
     });
   }

  onRemoveProduct = slug => {
    removeFromFavoriteList(slug);
    this.refreshState();
  }

  componentDidMount() {
    this.refreshState();
  }

  render () {
    let { props, state } = this;
    let categoriesKeys = props.data.allWcProductsCategories.edges.map(({ node: category }) => category.name);
    return (
      <Layout
        navigate={props.navigate}
        categories={props.data.allWcProductsCategories || {edges: []}}>
        <SEO 
          title="Produits que vous aimez"
          keywords={[`aalladine`, `ecommerce boutique`, ...categoriesKeys]} />
        <div className="page-favorite-products">
          <h1 className="favorite-products__title container__main-title">Votre produits aimées</h1>
          {
            state.likedProducts.map( (rows, i) => (
              <div className="row" key={i}>
                {
                  rows.length ?
                    rows.map(product => {
                      product = { ...product, images: [{src: product.image.src || product.image, id: product.image.id || product.image}] }
                      return (
                        <Card key={product.slug} data={product} isLiked removeEvent={this.onRemoveProduct} />
                      )
                    }) :
                    (
                      <div className='empty-basket empty-bascket center'>
                        <img src={EmptyBasketIcon} height={200} alt="panier est vide"/>
                        <h4 className='favorite-products__resutls'>
                          Oop's! Votre favorite produits est vide!&nbsp;
                          Visitez <Link to='/nouveax-produits' title="Derniers produits">Le derniers produits</Link>, &nbsp;
                          ou revenir vers  <Link to='/' title="page d'accuil">la page d'accueil</Link>.
                        </h4>
                      </div>
                    )
                }
              </div>
            ))
          }
        </div>
      </Layout>
    )
  }
}

export default FavoriteProducts;

export const pageQuery = graphql`
  query {
    allWcProductsCategories (
      filter : { slug : {ne: "uncategorized"} },
      sort: { fields: [count], order: DESC }
    ) {
      edges {
       node {
         id
         name
         slug
         display
         count
         description
         _links {
           self {
             href
           }
         }
         image{
           id
           src
           name
           alt
         }
       }
      }
    }
  }
`
